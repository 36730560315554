import React, { useState, createRef } from "react"
import MyMap, { GoogleMap, MapPoint, Marker, SearchBox } from './myMap'

const LocationSelector = () => {
    const [ position, setPosition ] = useState<MapPoint>(null)
    const [ center, setCenter ] = useState<MapPoint>({
      lat: 49.81749199999999,
      lng: 15.472962
    })
    const [ bounds, setBounds ] = useState(null)
    const searchBoxRef = createRef<SearchBox>()
    const googleMapRef = createRef<GoogleMap>()
  
    const onBoundsChanged = () => {
      const googleMap = googleMapRef.current
      if (googleMap) {
        setBounds(googleMap.getBounds())
      }
    }
    const onPlacesChanged = () => {
      const searchBox = searchBoxRef.current
      const googleMap = googleMapRef.current
      if (searchBox && googleMap) {
        const places = searchBox.getPlaces();
        const bounds = new google.maps.LatLngBounds();
  
        places.forEach(place => {
          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport)
          } else {
            bounds.extend(place.geometry.location)
          }
        });
  
        googleMap.fitBounds(bounds);
  
        if (places[0]) {
          setPosition({
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng()
          })
        }
      }
    }
  
    return (
      <MyMap
          mapProps={{
            center: center,
            zoom: 7,
            onClick: (e) => setPosition(e.latLng),
            ref: googleMapRef,
            onBoundsChanged: onBoundsChanged
          }}
        >
          <SearchBox
        ref={searchBoxRef}
        bounds={bounds}
        controlPosition={2}
        onPlacesChanged={onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Vyhledat místo na Mapách"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `90%`,
            maxWidth: `320px`,
            height: `32px`,
            marginTop: `16px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </SearchBox>
          { position != null && <Marker position={position} />}
        </MyMap>
    )
}

export default LocationSelector;
import * as React from 'react'

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from 'react-google-maps'
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'
import SearchBox from 'react-google-maps/lib/components/places/SearchBox'

export interface MapPoint {
    lat: number
    lng: number
}

export interface MapProps {
    zoom: number
    center: MapPoint
    onClick?: (e: any) => void
    ref?: React.LegacyRef<GoogleMap>,
    onBoundsChanged?: () => void
}

interface GoogleMapComponentProps {
    mapProps: MapProps,
    children?: React.ReactNode
}

const GoogleMapComponent = withScriptjs(withGoogleMap((props: GoogleMapComponentProps) => {
    return (
        <GoogleMap
            {...props.mapProps}
            defaultOptions={{ 
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControlOptions: {
                    position: 12
                  },
             }}
        >
            {props.children}
        </GoogleMap>
    )
}))
  
const MyMap = (props: GoogleMapComponentProps) => {
    return (
        <GoogleMapComponent 
            // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDCZ7-TvVEPbmMANvlk3opq95agdr8F3Cs&v=3.exp&libraries=geometry,drawing,places"
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBb1AxnWCmUvyWQGg4HbQQRo4SqCg5MVzI&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `60ex` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            {...props}/>
    )
}

export default MyMap;
export { GoogleMap, Marker, InfoBox, SearchBox }
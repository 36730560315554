import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LoadedData from '../model/loadedData'
import StoneData from '../model/stoneData'
import { getStoneImages, getStones, getStoneComments } from "../model/firebase"
import StoneComments from "../model/stoneComments"
import MyMap, { Marker, InfoBox, GoogleMap } from "../components/maps/myMap"
import LocationSelector from "../components/maps/locationSelector"

type MapPoint = {
    lat: number,
    lng: number
}

const getPointFromComment: (comment: StoneComments) => MapPoint = (comment) => {
    return {
        lat: comment.location.latitude,
        lng: comment.location.longitude
    }
}

const MapWithComments = (props : { stoneComments: Array<StoneComments>}) => {
    const [ openStone, setOpenStone ] = useState(-1)
    const onToggleOpen = (stone) => {
        if (openStone == stone)
            return setOpenStone(-1)
        setOpenStone(stone)
    }

    const bounds = new google.maps.LatLngBounds();
    let point = { lat: 50.0596486, lng: 14.3955851 }
    for (let comment of props.stoneComments) {
        point = getPointFromComment(comment)
        bounds.extend(point)
    }

    return (
        <MyMap
            mapProps={{
                zoom: 12,
                center: point,
                ref: map => map && map.fitBounds(bounds)
            }}>
            {props.stoneComments.map((comment, index) => {
                return <Marker
                            key={index}
                            position={getPointFromComment(comment)}
                            onClick={() => onToggleOpen(index)}
                        >
                            {openStone == index && <InfoBox
                                onCloseClick={() => onToggleOpen(index)}
                                options={{ closeBoxURL: ``, enableEventPropagation: true }}
                            >
                                <div style={{ backgroundColor: `lightGray`, opacity: 0.75, padding: `12px`, fontSize: `16px` }}>
                                    {comment.comment}
                                </div>
                            </InfoBox>}
                        </Marker>
            })}
        </MyMap>
    )
}

const Comments = (props: {comments: Array<StoneComments>}) => {
    return (
        <>
        { props.comments.map(comment => {
            if (comment.comment)
                return <div>date: {new Date(comment.date.seconds * 1000).toDateString()} comment: {comment.comment}</div>
            return ''
        }) }
        </>
    )
}

const StonePage = ({ location }) => {
    const [ stoneUid, setStoneUid ] = useState("")
    const [imageSrc, setImageSrc] = useState('http://placehold.it/512');
    const [stoneData, setStoneData] = useState<LoadedData<StoneData>>({
        loading: false,
        data: null
    });

    const [stoneComments, setStoneComments] = useState<LoadedData<Array<StoneComments>>>({
        loading: false,
        data: null
    });

    useEffect(() => setStoneUid(new URLSearchParams(location.search).get("id") || ""), [])
    useEffect(() => {
        // if (stoneData.data == null)
        //     return
        getStoneImages(stoneUid)
            .getThumbnail()
            .then(setImageSrc)
    }, [stoneUid])

    useEffect(() => {
        setStoneData({...stoneData, loading: true});

        if (stoneUid == "")
            return

        return getStones().doc(stoneUid).onSnapshot(doc => {
            if (!doc.exists)
                return setStoneData({...stoneData, loading: false});

            let stone: StoneData = { ...doc.data(), uid: stoneUid };

            setStoneData({
                loading: false,
                data: stone
            })
        })
    }, [stoneUid]);

    useEffect(() => {
        setStoneComments({...stoneComments, loading: true});

        if (stoneUid == "")
            return

        return getStoneComments(stoneUid).limit(10).onSnapshot(snapshot => {
            let comments: Array<StoneComments> = [];

            snapshot.forEach(doc =>
                comments.push({ ...doc.data(), uid: doc.id }),
            );

            setStoneComments({loading: false, data: comments})
        })
    }, [stoneUid]);

    return (
        <Layout>
            <SEO title="Stone" />
            <h2>Stone</h2>
            <p>ID: {stoneUid}</p>

            <div style={{
                height: '40ex',
                width: '40ex',
                backgroundImage: 'url("' + imageSrc + '")',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                }}></div>

            {/* <div>{JSON.stringify(stoneData)}</div> */}

            { stoneData.data?.name
                ? <p>Název: {stoneData.data.name}</p>
                : ''
            }
            { stoneData.data?.story
                ? <p>Příběh: {stoneData.data.story}</p>
                : ''
            }

            <div>Komentáře</div>
            { stoneComments.data
                ? <Comments comments={stoneComments.data} />
                : 'Loading comments ...'
            }
            <div>MAPA</div>
            { stoneComments.data
                ? <MapWithComments stoneComments={stoneComments.data} />
                : 'Loading map ...'
            }
            <div>MAPA - selector</div>
            <LocationSelector />
        </Layout>
    )
  }

export default StonePage
